import api from './api';


class ReportService {
    async getReports() {
        var reports = []

        const response = await api.get("/report");

        reports = response.data
      
        return reports;
    }
    async getReport(id) {
        var report = {}

        const response = await api.get("/report/"+id);
      
        report = response.data     

        return report;
    }     
    async getCustomerReports(id) {
        var customer_report_list = []
        const response = await api.get("/report?customer_id="+id);
  
        customer_report_list = response.data;
        
        return customer_report_list;
    }
    async getItemReports(id) {
        var customer_report_list = []

        const response = await api.get("/report?item_id="+id);
  
        customer_report_list = response.data;
        
        return customer_report_list;
    }
    async getReportsByWorkloadId(id) {
        var customer_report_list = []

        const response = await api.get("/report?workload_id="+id);
  
        customer_report_list = response.data;
        
        return customer_report_list;
    }    
    async deleteReport(id) {

        const response = await api.delete("/report/"+id);
  
        return response.data;        
    }
    async getReportStatusList() {
        var status = []

        const response = await api.get("/report/status");

        status = response.data
      
        return status;
    }  
    async saveReport(report_id, remediation_items, action_items) {
        var request = {
            "report_remediation_items": remediation_items,
            "report_action_items": action_items
        }
        const response = await api.put("/report/" + report_id, request);
      
        return response.data;
    }   
    async createReport( name, customer_id, workload_id, status, remediation_items) {
        var request = {
            "report_name": name,
            "report_customer_id": customer_id,
            "report_workload_id": workload_id,
            "report_status": status,
            "report_remediation_items": remediation_items,
        }
        const response = await api.post("/report", request);
      
        return response.data;
    }
    async generateReport(report_id, customer_id, workload_id) {
        var request = {
            "customer_id": customer_id,
            "workload_id": workload_id   
        }
        const response = await api.post("/report/" + report_id + "/generate", request);
        
        return response.data.body;
    }
    
}    
  
export default new ReportService();