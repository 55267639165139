<template>
  <CRow>
    <CToaster :autohide="2000">
      <template>           
        <div v-if="showSuccessToast">
          <CToast          
          :show.sync="showSuccessToast"
          color="success"
          >
            {{ successToastMessage }}
          </CToast>
        </div>   
      </template>         
      <template>           
        <div v-if="showErrorToast">
          <CToast          
          :show.sync="showErrorToast"
          color="danger"
          >
            {{ errorToastMessage }}
          </CToast>
        </div>   
      </template>               
    </CToaster>
    <CCol v-show="loading">
      <CCard>
        <CCardBody>
          <p class="text-center" v-show="loading">
            <CSpinner />
          </p >   
        </CCardBody>
      </CCard>      
    </CCol>
    <CCol col="12" lg="8" v-show="!loading">
      <CCard style="max-width: 40rem;">
        <CCardHeader>
          Create Report
        </CCardHeader>          
          <CCardBody>
            <CRow col="12" >
              <CCol col="8" class="mb-2">
                <template>
                  <div :class="{ 'invalid': $v.selectedCustomer.$invalid && $v.$anyDirty  }">
                    <label class="typo__label">Customer</label>
                    <multiselect 
                      v-model="$v.selectedCustomer.$model"
                      :options="customers" 
                      :searchable="true" 
                      :close-on-select="true"   
                      label="customer_name"               
                      placeholder="Pick or search a customer"
                      :disabled="inputDisabled"
                      @input="customerSelected"
                    />             
                    <label class="typo__label form__label mt-2" > 
                      <div style="color:#768192 !important; font-size: 80%">
                        Select first the Customer. Customers without a Workload are disabled.
                      </div>
                    </label>
                  </div>
                  </template>
                </CCol>
                <CCol col="8" class="mb-2">                                     
                  <template>
                    <div :class="{ 'invalid': $v.selectedWorkload.$invalid && $v.$anyDirty  }">
                      <label class="typo__label">Workload</label>
                      <multiselect
                        v-model="$v.selectedWorkload.$model"
                        :options="customerWorkloads"
                        track-by="id"
                        :searchable="true"
                        :close-on-select="true"
                        :loading="workloadsLoading"
                        :disabled="selectWorkloadsDisabled"
                        :multiple="false"
                        label="label" 
                        :hideSelected="false"
                        placeholder="Pick a workload"
                        @input="workloadSelected"
                      />
                      <label class="typo__label form__label mt-2" > <div style="color:#768192 !important; font-size: 80%">Select the Workload to create a Report for.</div></label>                      
                    </div>
                  </template>  
                </CCol>
              </CRow>
              <CRow col="12">
                <CCol col="8">                                     
                <CInput
                  label="Report Name"
                  placeholder="Report Name"
                  v-model="reportName"
                  valid-feedback="Report name is valid."
                  description="The report name is automatically generated by thew HAWK Tool."
                  :disabled="true"
                  >
                  </CInput>               
                </CCol>
                <CCol col="4">                                         
                  <CSelect
                    label="Status"
                    :options="statusList"                
                    disabled
                    >
                    </CSelect>
                </CCol>
              </CRow>
        </CCardBody>
        <CCardFooter>
          <CButton color="light" class="col-sm-2" @click="goBack">Back</CButton>
          <CButton v-on:click="openReport();" color="success" class="float-right col-sm-4" v-show="showOpenReport" >Open Report</CButton>
          <CButton v-on:click="createReport();" color="success"  class="float-right col-sm-4" :disabled="!$v.$anyDirty || $v.$invalid" v-show="!inputDisabled">Create new Report</CButton>
        </CCardFooter>
      </CCard>
    </CCol>
  </CRow>
</template>

<!-- TODO: Disable customers without a workload in select  -->

<script>
import Multiselect from 'vue-multiselect'
import ReportService from '@/services/report.services';
import CustomerService from '@/services/customer.services';
import WorkloadService from '@/services/workload.services';
import { required, email, minLength, maxLength, helpers } from 'vuelidate/lib/validators';
import { kebabCase } from 'lodash';


export default {
  components: {
    Multiselect
  },  
  data() {
    return {
      loading: true,
      selectedCustomer: '',
      report: {},
      statusList: [],
      workloads: [],
      customerWorkloads: [],
      availableWorkloads: {},
      selectWorkloadsDisabled: true,
      customers: [],
      lenses: {},
      workloadsLoading: true,
      customerName: "",
      reportName: "",
      selectedWorkload: '',
      reportCreated: false,
      inputDisabled: false,
      nextDisabled: true,
      createDisabled: false,
      workloadLoading: true,
      reportFailedMsg: false,
      showOpenReport: false,
      showErrorToast: false,
      errorToastMessage: "",
      showSuccessToast: false,
      successToastMessage: "",
    };
  },
  setup: () => ({ v$: useVuelidate() }),    
  validations: {
    selectedCustomer: {
      required,
    },
    selectedWorkload: {
      required,
    },
  },
  methods: {
    validator (val) {
        var valid = false;
        
        const regex = new RegExp('^([a-zA-Z0-9-_\\.&!#=()\\s]){4,}$');
        valid = val ? regex.test(val)  : false;
        
        this.createCisabled = !valid;

        return valid
    },     
    goBack() {
      this.$router.push({path: '/reports'})
    },  
    openReport() {
      this.$router.push({path: '/reports/'+this.showOpenReportId})
    },             
    customerSelected(customer) {
      this.workloadLoading = true;
      var customerId = customer.customer_id;
      this.customerWorkloads = this.availableWorkloads[customerId];
      this.workloadLoading = false;
      this.selectWorkloadsDisabled = false;
    
    },
    workloadSelected(workload) {

      const d = new Date()
      const date = d.toISOString().split('T')[0];
      const time = d.toTimeString().split(' ')[0].replace(/:/g, '-');

      var tmpReportName = this.selectedCustomer.customer_name + "_" + workload.label + "_" + date + "-" + time
      
      this.reportName = kebabCase(tmpReportName);
    },
    async createReport() {
      this.customerName = this.selectedCustomer.customer_name;
      
      var tmp_remediation_items = []

      ReportService.createReport(
        this.reportName, 
        this.selectedCustomer.customer_id, 
        this.selectedWorkload.id, 
        this.statusList[0], 
        tmp_remediation_items
        ).then(
          response => { 
            this.successToastMessage = "Report for custome "+ this.selectedCustomer.customer_name +" created."
            this.showSuccessToast = true;

            this.inputDisabled = true;
            this.nextDisabled = false;
            this.showOpenReport = true;
            this.showOpenReportId = response.report_id;
          },
          error => {   
            this.errorToastMessage = "Error creating the Report."
            this.showErrorToast = true;            
            console.log(error);
          }      
      )
    }, 
    async processCustomerData() {
      for (var key in this.customers){
        if(this.availableWorkloads[this.customers[key].customer_id] == undefined){
          this.customers[key]['$isDisabled'] = true;
        };
      };
  },                  
  },

  created() { },
  async mounted() { 
    await ReportService.getReportStatusList().then(
      response => { 
        this.statusList = response;
      },
      error => {   
        console.log(error);
        this.errorToastMessage = "Error getting Report Status List. Please try again later.";
        this.showErrorToast = true;
      }  
    )
    await WorkloadService.getWorkloads(false).then(
      response => { 

        var tmp_workloads = response;
        for (var key in tmp_workloads) {
          var customerId = tmp_workloads[key].CustomerId
          
          if (!this.availableWorkloads[customerId]) {
            this.availableWorkloads[customerId] = []            
          }

          var tmp = {};
          tmp['id'] = tmp_workloads[key].WorkloadId;
          tmp['label'] = tmp_workloads[key].FriendlyName;
          this.availableWorkloads[customerId].push(tmp);

        };
        this.workloadsLoading = false;
      },
      error => {   
        console.log(error);
        this.errorToastMessage = "Error loading Workloads. Please try again later.";
        this.showErrorToast = true;
      }      
    )
    await  CustomerService.getCustomers().then(
      response => { 
        this.customers = response;
      },
      error => {   
        console.log(error);
        this.errorToastMessage = "Error loading Customers. Please try again later.";
        this.showErrorToast = true;
      }
    )
    await this.processCustomerData();
    this.loading = false;
  },  

  watch: { },


};

</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>